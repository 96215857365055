@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

@font-face {
    font-family: 'Mulish', sans-serif;
    src: url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');
}
html{
background-color: #000000;
scroll-behavior: smooth;
}

:root{
  --color-main: #FFD600;
    --color-main2: #FFD60075;
}

button{
  background-color: #00000000;
  border-color: #00000000;
  font-weight: 600;
  color: var(--color-main);
  font-size: 1.6rem;
}

h1{
  /* color: var(--color-main); */
  padding: 1rem;
  font-size: 7.5vw;
  font-weight: 900;
  margin-bottom: 0.1rem;
  line-height: 1;
  background: linear-gradient(92deg, #ffae00 0%, #ff6900 30%, #ff00ef 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.TabsStyle{
  background-color: #000;
  width: 80%;
  margin-left: 10%;
}

.Tabs{
  text-transform: none;
  border: 1px solid;
}

#role{
color:gray;
font-weight: 100;
}

#TabsID{
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr 2fr 2fr;
  width: fit-content;
}

#sj_tab{
  color: white;
  text-transform: none;
  border-bottom: .3rem solid;
  text-align: left;
  font-size: 1rem;
  font-family:  'Mulish', sans-serif;

}

#sj_tab:focus{
  color: var(--color-main);
}

#sj_tab_inactive{
  color: gray;
  text-transform: none;
  border-style: solid;
  border-bottom: #00aa87;
  border: 1px;
}

#sj_tab:hover{
  color:var(--color-main);
}

h2{
  font-size: 8rem;
  font-weight: 700;
  margin-bottom: 0.1rem;
  margin-top: 2rem;
}

h3{
  margin-bottom: 0;
}
#isto{
  color: var(--color-main);
}
a{
  color: var(--main-color);
  line-height: 1.6rem;
    text-decoration: none;
}

a h3{
text-decoration: underline;
}
.prst{
        grid-template-columns: repeat(12, 1fr);
}

.Prostina2{
  display: grid;

      grid-template-rows: repeat(2,1fr);
      grid-column-gap: 1rem;
      grid-row-gap: 1rem;
      grid-template-areas: "A A A A A B B D D D D D" "A A A A A B B C C C C C";
      width: 90%;
      margin-left: 5%;
      height: 30rem;
}

.prst > div{
  border-radius: .5rem;
}

.prst > div span{
  color: black;
    padding: 0.5rem 1rem;
    bottom: 0;
    position: absolute;
    width: fit-content;
    line-height: 1.1;
}

.Prostina2_1{
  margin-top: 1rem;
  display: grid;

      grid-template-rows: repeat(2,1fr);
      grid-column-gap: 1rem;
      grid-row-gap: 1rem;
      grid-template-areas: " D D D D D A A A A A B B" " C C C C C A A A A A B B";
      width: 90%;
      margin-left: 5%;
      height: 30rem;
}

.Prostina2_2{
  margin-top: 1rem;
  display: grid;

      grid-template-rows: repeat(2,1fr);
      grid-column-gap: 1rem;
      grid-row-gap: 1rem;
      grid-template-areas: "  B B D D D D D A A A A A " "  B B C C C C C A A A A A";
      width: 90%;
      margin-left: 5%;
      height: 30rem;
}

.ProstinaItemA{
  display: flex;
  position: relative;
  background: #ffffff;
  grid-area: A;

  height: 100%;
}
.ProstinaItemB{
  display: flex;
  position: relative;
  background: #ffffff;
grid-area: B;

  height: 100%;
}
.ProstinaItemC{
  display: flex;
  position: relative;
  background: #ffffff;
grid-area: C;

  height: 100%;
}
.ProstinaItemD{
  display: flex;
  position: relative;
  background: #ffffff;
grid-area: D;

  height: 100%;
}

.timeline {
  display: grid;
  /* grid-template-rows: repeat(6, auto); */
  /* grid-row-gap: 0.3rem; */
  row-gap: 0.3rem;
  grid-template-columns: 1fr 2fr 1fr 1fr 2fr;
  /* grid-template-rows: 1fr 2fr 1fr 1fr 2fr; */
  width: 100%;

}

.timeline p{
  font-size: .9rem;
  margin-right: 5%;
  line-height: 1.2;
}

.timeline div{
border-bottom: 0.5rem solid;
}

#wnamenew{
  font-size: 1rem;
  font-weight: 900;
}

#colALS{
      border-color: #ba0b27;
}

#colAdv{
      border-color: #ff5046;
}

#colSber{
      border-color: #21a039;
}
#colSJ{
      border-color: #00aa87;
}

.workTable{
  display: flex;
  grid-template-columns: repeat(12, 1fr);
  /* grid-template-areas: "A A A B B B B B B B B B B B B "; */
  width: 90%;
  margin-left: 5%;
  grid-column-gap: 2rem;
  column-gap: 2rem;
  flex-direction: column;
}

.dynamicD{
  position: relative;
  width: 100%;
  grid-area:B;
}

.dynamicD p{
  font-size: 3rem;
  line-height: 1.2;
  font-weight: 800;
}

.dynamicD h1{
  font-size: 7rem;
  max-width: 70%;
      /* margin: 0; */
  margin-left: -2%;
  margin-top:3%;
}

.dynamicD span{
background: var(--color-main);
color: black;
font-size: 3rem;
font-weight: 900;
padding: .1rem 1rem;
max-width: 30%;
right:0%;
top:13%;
position: absolute;
}

.scrollableContainer{
  display: none;
}

.scrollableContainer::-webkit-scrollbar {
    display: none;
}

#divList{
  position: relative;
  display: block;
  width:80%;
  margin-left: 10%;
  margin-top: 1%;
}

#divList a{
  }

.list1 {
  display: flex;
      justify-items: start;
      align-content: center;
      justify-content: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
      row-gap: 2rem;
      width: 100%;
      margin-left: -2.5%;
}

.list {
  display: flex;
  overflow-x: auto;
  list-style: none;
  padding: 0;
  padding: 1rem;
  -ms-overflow-style: none;
   scrollbar-width: none;
   overflow-y: scroll;
}

.list::-webkit-scrollbar {
    display: none;
  }

.item {
display: flex;
 align-items: center;
 width: auto;
}

.item:not(:last-child) {
  margin-right: .1em;
}

.description{
  position: relative;
  top:-1rem;
  font-size: 1.1rem;
  margin-top: 0;

}

.App {
display: grid;
background-color: #000000;
min-height: 100vh;
display: flex;
flex-direction: column;
font-family: 'mulish', sans-serif;
font-size: 1.5vw;
  line-height: 1.6;
color: white;
}

.NewHeader{
  display: flex;
  align-items: center;
  background: #0B0404;
  height: 60vh;
}

.wraper{
  display: flex;
  margin-left: 10%;
      flex-direction: column;
      max-width: 80%;
}

.lock{
  position: absolute;
z-index: 3;
font-size: 1.4rem;
background: var(--color-main);
color: black;
padding-left: 0.8rem;
padding-right: 0.9rem;
border-radius: 1rem;
top: 10%;
width: fit-content;
line-height: 1.3;
padding-top: 0.4rem;
padding-bottom: 0.4rem;
opacity: 0;
transition: 1s;
filter: drop-shadow(2px 4px 7px black);
}

.prjLink{
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;
  grid-area: A;
}

#lockicon{
  top: 1%;
left: 1%;
    position: absolute;
    width: 64px;
    z-index: 3;
}


#lockicon_topright{
  top: 1%;

    position: absolute;
    width: 64px;
    z-index: 3;
}

#lockicon_bottomright{
  bottom: 1%;
  right: 1%;
    position: absolute;
    width: 64px;
    z-index: 3;
}

#lockicon_bottomleft{
  bottom: 1%;
  left: 1%;
    position: absolute;
    width: 64px;
    z-index: 3;
}

.locDiv:hover .lock{
  opacity: 1;
}
.locDiv{
  position: relative;
}

.doBlock{
  display: grid;
      width: fit-content;
      max-width: 90%;
      margin-left:5%;
      grid-template-columns: 25% 25% 25% 25%;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: flex-start;
      transition: .7s;
      margin-top: 7%;
      margin-bottom: 7%;
}

.doBlock>div h3{
  transition: .5s;
}

.doBlock>div:hover h3{
transform: rotate(5deg) scale(1.1);
}
.doBlock>div  #rako{
  transition: .6s;
}
.doBlock>div #rako1{
transition: .6s;
}
.doBlock>div #kus{
transition: .6s;
}
.doBlock>div #wrk1{
transition: .6s;
}
.doBlock>div:hover #rako{
  transform: scale(1.1);
  border-radius: 5rem 0rem 0 1rem;
}
.doBlock>div:hover #rako1{
  transform: scale(1.2);
  border-radius: 0;
}
.doBlock>div:hover #kus{
  transform: scale(1.2);
  border-radius: 0 2rem 0 5rem;
}
.doBlock>div:hover #wrk1{
  transform: scale(.6);
  border-radius: 10rem;
}

.prostina{
  display: grid;
      width: fit-content;
      grid-template-areas:
      "A A A A A A A A B B B B"
      "C C C C C C D D G G G G"
      "E E E E F F F F F F F F";
      grid-gap: 1rem;
      align-items: start;
      /* justify-content: space-evenly; */
      /* align-items: flex-start; */
}

.doWrap{
  width: 80%;
}

.card{
  grid-area: A;
  border-radius: 1rem;
}
.card1{
  grid-area: D;
  border-radius: 1rem;
}
.card2{
  grid-area: D;
  border-radius:2rem 10rem 24rem;
}
.card2 video{
    border-radius:2rem 10rem 24rem;
}

#rrraWrap{
  grid-area: A;
}

#rrr{
  position: relative;
  width: 100%;
  height: 0;
    border-radius: 100rem 10rem 53rem 13rem;
  overflow: hidden;
  padding-bottom: 57%;
  display: flex;
}

#rrrKV{
  position: absolute;
  width: 100%;
  transition: 10s;
}

#rrrLogo{
  position: absolute;
  width: 100%;
  transition: 2s;
}

#rrr:hover #rrrKV{
  transform: scale(1.3);
}

#rrr:hover #rrrLogo{
  transform: scale(1.5);
}

#sberteamWrap{
grid-area: D;
}

#sberteam{
  position: relative;
  display: flex;
  overflow: hidden;
  background: #6d49ff;
  width: 100%;
  height: 0;
  padding-bottom: 70%;
  border-radius: 1rem;
}

#sberteam img{
  position: absolute;
}
#sbt11{
  transition: 9s;
  width: 8%;
}

#sbt3{
  right: 7%;
  bottom: 16%;
    transition: 10s;
        width: 20%;
  }

#sbt4{
  right: 21%;
    bottom: 0;
      transition: 9s;
          width: 25%;
  }
#sbt5{
  right: 0;
bottom: 0;
  transition: 7s;
  width: 25%;

}
#sbt6{
    transition: 6s;
    width: 20%;
}
#sbt7{
  top: 10$;
  left: 30%;
    transition: 7.5s;
        width: 20%;
}
#sbt8{
  right: 0;
  bottom: 0;
    transition: 9s;
        width: 40%;
}
#sbt9{
  left: 0;
top: 0;
  transition: 7s;
  width: 40%;
}

#sbt1{
      right: 5%;
      transition: 4s;
          width: 60%;
}

#sbt10{
  left: 32%;
bottom: -4%;
      transition: 5s;
      width: 30%;
}

#sbt2{
  width: 54%;
top: -6%;
left: 1%;
      transition: 3.5s;
}


#sberteam:hover #sbt11{
  transform: translateY(-70%);
}

#sberteam:hover #sbt8{
  transform: scale(1.5);
}

#sberteam:hover #sbt9{
  transform: translateX(-5%) scale(1.3);
}

#sberteam:hover #sbt3{
  transform: translateX(-15%) scale(1.3) rotate(25deg);
}
#sberteam:hover #sbt4{
  transform: translateX(-23%) scale(.9) rotate(-30deg);
}
#sberteam:hover #sbt5{
  transform: translateX(-20%) scale(1.2) rotate(4deg);
}

#sberteam:hover #sbt1{
  transform: translateX(20%) scale(1.05) rotate(25deg);
}
#sberteam:hover #sbt10{
  transform: translateX(-5%) scale(1.1) rotate(-15deg);
}

#sberteam:hover #sbt2{
  transform: translateX(5%);
}


#wrap180{
  grid-area: A;
}

#slider180{
position: absolute;
border-radius: 100rem;
overflow: hidden;
left: 2.92%;
right: 58.94%;
top: 42%;
bottom: 41.3%;
background: linear-gradient(104.47deg, #09DB00 -0.27%, #B0E300 95.6%);
z-index: 0;
}

#slhead{
  position: absolute;
  top: 40%;
  left: 1%;
  transition: 1.7s;
  z-index: 2;
  width: 10.8%;
}

#subslider180{
  position: absolute;
  background: linear-gradient(82.71deg, #EEF3FF 10.66%, #FFFFFF 73.92%);
  width: 120%;
  height: 120%;
  transition: 1.6s;
  z-index: 1;
}

#bwfilter{
  position: absolute;
  width: 100%;
  height: 100%;
  background: black;
  mix-blend-mode: hue;
  transition: 3s;
}

#sbjersey{
  position: absolute;
left: 55%;
top: 10%;
transition: 9s;
    width: 50%;
}

#sbpin{
  position: absolute;
      left: 40%;
      top: -16%;
      width: 34%;
      transition: 8s;
}

#sbbag2{
  position: absolute;
  left: 67%;
  top: 17%;
  width: 32%;
  transition: 7s;
}

#sbbottle{
  position: absolute;
  left: 39%;
  top: 11%;
  width: 38%;
  transition: 10s;
}

#sbbag{
  position: absolute;
      left: 68%;
      top: 36%;
      width: 42%;
      transition: 11s;
}



#sbblogo{
  position: absolute;
width: 100$;
top: 26%;
left: 8%;
width: 67%;
z-index: 0;
}

#wrap180:hover #subslider180{
  transform: translateX(90%);
  transition: 1.8s;
}

#wrap180:hover #slhead{
  transform: translateX(300%);
}

#wrap180:hover #bwfilter{
  opacity: 0;
}

#wrap180:hover #sbpin{
  transform: scale(1.2) translate(-10%,-10%);
}
#wrap180:hover #sbbag2{
  transform: scale(1.2) translate(10%,-10%);
}

#wrap180:hover #sbbottle{
  transform: scale(1.3) translate(-10%,10%);
}

#wrap180:hover #sbbag{
  transform: scale(1.4) translate(10%, 10%);
}

#wrap180:hover #sbjersey{
  transform: scale(1.2) rotate(10deg);
}

#pic180{
  position: relative;
  display: flex;
  position: relative;
  overflow: hidden;
width: 100%;
height: 0;
padding-bottom: 50%;
border-radius: 1rem;
background: radial-gradient(42.07% 39.8% at 3.43% 103.89%, #61EFBD 0%, rgba(168, 239, 226, 0) 100%),
radial-gradient(32.76% 57.78% at 64.68% 12.33%, #FEF64C 0%, rgba(239, 232, 168, 0) 100%),
radial-gradient(20.75% 45.93% at 9.28% 6.56%, #63F96C 0%, rgba(168, 239, 172, 0) 100%),
#FFFFFF;
filter: grayscale(1);
transition: 2s;
}

#pic180:hover{
  filter: grayscale(0);
}

#bitvaWrap{
  grid-area: B;
}

#bitva{
  position: relative;
  width: 100%;
  height: 0;
  border-radius: 1rem;
  overflow: hidden;
  padding-bottom: 57%;
  display: flex;
}

#bitva img{
  width: 100%;
  position:absolute;
}
#b1{
  transition: 10s;
}
#b2{
  transition: 1s;
}

#bitva:hover #b2{
  transform: translateX(100%);
}
#bitva:hover #b1{
    transform: scale(1.4);
}


#d img{
  position: absolute;
}

#din{
  width: 100%;
}

#dout{
  width: 100%;
}

#dleft{
  width: 29%;
  left: 21.5%;
  bottom: -2%;
  transition: 2s;
}

#horseman{
  width: 75%;
  left: 15.5%;
  bottom: 4%;
  transition: 2s;
  animation: dance 1s infinite;
}

@keyframes dance {
    0% { transform: rotateY(0) scalex(1) scaleY(1); }
    20% {transform: rotateY(0) scale(1) scaleY(1);}
    49% { transform: rotateY(0);}
    50% { transform: rotateY(180deg) scaleX(1) scaleY(1); }
    70% {transform: rotateY(180deg) scaleX(1) scaleY(1);}
    99% { transform: rotateY(180deg); }
}
@keyframes rotateportal {
        0% { transform: rotateZ(0); }
        100% { transform: rotateZ(360deg);
        }
}

@keyframes levitation {
    0%{transform: translateY(0);}
    50%{transform: translateY(10%);}
    100%{transform: translateY(0);}
}

#portalimg{
  position: absolute;
  width: 100%;
  left: -150%;
  animation: rotateportal 3s infinite linear;
}

#portaldiv{
  position: relative;
  grid-area: B;

}

#portalHeart{
  position: absolute;
  width: 34%;
  right: 12%;
  top:5%;
  animation: levitation 2s infinite;
}

#portaliphone{
  position: absolute;
  width: 20%;
  right: 20%;
  top: 25%;
  animation: levitation 4s infinite;
}

#portalgraphic{
  position: absolute;
  width: 45%;
  right: 30%;
  top: 10%;
  animation: levitation 3s infinite;
}


#portal {
position: relative;
    transform: rotateY(80deg) rotateX(15deg);
    left: -20%;
}

#dright{
  right: 22%;
      /* top: 0%; */
      width: 28.5%;
      bottom: -2%;
      transition: 2s;
}

#d{
  display: flex;
  position: relative;
  grid-area: B;
  height: 0;
  width: 100%;
  padding-bottom: 122%;
  overflow: hidden;
  border-radius: 1rem;
      margin-bottom: 1rem;
}

#d:hover #dright{
  transform: translateX(100%);
}

#d:hover #dleft{
  transform: translateX(-100%);
}

.card .Plate{

}

.cardB{
  grid-area: B;
}

.cardB .Plate{
  transform: rotate(5deg);
}

.cardC{
  grid-area: C;
}

.cardC .Plate{
  transform: rotate(2deg);
}

.cardD{
  grid-area: D;
}

.cardD .Plate{
  transform: rotate(-4deg);
}

.cardE{
  grid-area: E;
}

.cardE .Plate{
  transform: rotate(-1deg);
}

.cardF{
  grid-area: F;
}

.cardF .Plate{
  transform: rotate(3.5deg);
}

#ducks{
  position: relative;
  width: 100%;
}

.kusHeader{
  margin: 0;
}

.kusText{
  margin: 0;
}

#ducksWrapper{
  overflow: hidden;

  width: 100%;
}

.doCard{
  width:100%;
}

.doCard img{
  width: 45%
}

.do{
  width:100%;
}

.description_1{
  z-index: 2;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 65%;
  margin-left: 10%;
  margin-top: 3em;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: baseline;
}

.newPicRules{
  width: 100%;
  z-index: 1;
}

video{
  z-index: 1;
}

#cardlift{
  position: relative;
width: 100%;
grid-area: B;
}

.Plate{
  color: var(--color-main);
  width: -moz-fit-content;
  width: fit-content;
  max-width: 28rem;
  z-index: 2;
  margin-top: 0rem;
      line-height: 109%;
  font-weight: 600;
}

.s_img{
  width:10%;
}

#s_ellipse{
  position: absolute;
  width: 70%;
  left: 15%;
}
#s_box{
  position: relative;
  text-align: center;
}
#s_salute{
  position: absolute;
  width: 30%;
  margin-left: -20%;
  top: -14%;
  left: 55%;
  mix-blend-mode: color-dodge;
  transition: 5s;

}

#s_heart{
  position: absolute;
  width: 25%;
  top: 50%;
  /* left: 15%; */
  /* align-self: center; */
  /* top: 50%; */
  /* margin: -50px 0 0 0; */
  left: 50%;
  ma: 0;
  margin-left: -13%;
  margin-top: -13%;
  transition: 5s;
}

#s_funnel{
  position: absolute;
    right: 25%;
    bottom: 3%;
    transition: 4s;
}

#s_pichart{
  position: absolute;
    width: 7%;
    left: 21%;
    bottom: 7%;
    transition: 4s;
}

#s_laptop{
  position: absolute;
right: 31%;
top: -13%;
width: 15%;
transition: 4s;
}

#s_portfl{
  position: absolute;
    left: 36%;
    width: 10%;
    transition: 3s;
}

#s_calc{
  position: absolute;
top: 7%;
left: 25%;
    transition: 3s;
}

#s_present{
  position: absolute;
  left: 46%;
  width: 23%;
  top: 18%;
      transition: 3s;
}

#s_iphone{
  position: absolute;
top: 3%;
width: 10%;
left: 34%;
    transition: 2s;
}

#deco{
  position: absolute;
  z-index: 1;
      align-self: end;
}
#catdec{
  position: absolute;
  z-index: 1;
  right: 0;
      align-self: end;
}

#kus {
    border-radius: 5rem 0 5rem 0;
}

#rako{
      border-radius: 2rem 5rem 3rem 0;
}

#rako1{
      border-radius: 6rem 5rem 0rem 1rem;
}

.About{
  background-color: #00000095;
  padding: 1rem;
  font-size: 3rem;
  line-height: 1.2;
  font-weight: 800;

}

#delau1{

}

#kusBckg{
  display: grid;
  grid-template-columns: 1fr 5fr 1fr;
    column-gap: 1%;
    padding: 1.5rem;
}
#kusName{
      font-size: 1rem;
      margin: 0;
}

#kusWrap{
  margin: 2rem;
  border-radius: 2rem;
  padding: 2rem;
  padding-top: 0;
  background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(32,32,32,1) 100%);
}

#dlyanachala{
  margin-top: 8rem;
  margin-bottom: 4rem;
}

#basement{
  display: flex;
      align-items: center;
      justify-content: flex-end;
      align-content: center;
      flex-wrap: wrap;
      flex-direction: column;
      margin-top: 25rem;
      line-height: 0.1rem;
}

#decor1{
  position: absolute;
  left: 0;
  z-index: 1;
  width:30%
}


#decor2{
  position: absolute;
  right: 0;
  z-index: 1;
  width:30%
}

#mail{
  z-index: 2;
}

#mailblock{
  text-align: center;
}

#wrapperWork{
  margin-top: 1rem;
  display: grid;
  grid-template-columns: .45fr .9fr 0.45fr;
  width: 90%;
  column-gap: .7rem;
}

#want{
  text-decoration: underline;
  text-decoration-style: dotted;
  margin-top: 1.5rem;
  line-height: 1.4;
  color: var(--color-main)
}

#wrapperWork2{
  display: grid;
  grid-template-columns: .4fr 1fr 0.5fr;
  width: 90%;
  column-gap: .7rem;
  margin-top: .7rem;
}

#artleb{
/* filter: drop-shadow(0 0 5px #ba0b2775); */
width: 20rem;
border-color: #ba0b27;
}
#artleb2{
/* filter: drop-shadow(0 0 5px #ba0b2775); */
width: 42rem;
border-color: #ba0b27;
}

#fr{
/* filter: drop-shadow(0 0 5px var(--color-main2)); */
width: 42rem;
}

#sber{
  /* background: #21A038; */
/* filter: drop-shadow(0 0 5px #21a03875); */
width: 40rem;
border-color: #21a038;
}

#sj{
    /* filter: drop-shadow(0 0 5px #00aa8775); */
    transition: 1s;
    width: 20rem;
    border-color: #00aa8775;
}

#sj #wtime{
  background: var(--color-main);
color: black;
padding-left: 4%;
padding-right: 4%;
padding-top: 1%;
padding-bottom: 1%;
border-radius: 2rem;
transform: rotate(10deg);
font-size: 1.5rem;
}

#advnz{
  transition: 1s;
  /* filter: drop-shadow(0 0 5px #ff504675); */
  width: 18rem;
  border-color: #ff5046;
}
#newsuperjob_articles{
  width: 80%;
  margin-left: 10%;
  display: grid;
  grid-template-areas: "A A A A A A A A B B B B";
  grid-template-columns: repeat(12, 1fr);
  column-gap: 1rem;
  row-gap: 1rem;
  margin-top: 1rem;
}

#newsuperjob_articles2{
  width: 80%;
  margin-left: 10%;
  display: grid;
  grid-template-areas: "B B B B A A A D D D D D" ;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 1rem;
  row-gap: 1rem;
  margin-top: 1rem;
}

#newsber_articles{
  width: 80%;
  margin-left: 10%;
  display: grid;
  grid-template-areas: "A A A A B B B D D D D D" ;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 1rem;
  row-gap: 1rem;
  margin-top: 1rem;
}
#newsber_articles2{
  width: 80%;
  margin-left: 10%;
  display: grid;
  grid-template-areas: "A A A A A A A D D D D D" ;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 1rem;
  row-gap: 1rem;
  margin-top: 1rem;
}


#newsuperjob{
  display: flex;
position: relative;
overflow: hidden;
height: 0;
text-align: center;
padding: 12%;
background: radial-gradient(63.28% 88.1% at 100% 6.16%, #2BFEBF 0%, rgba(1, 197, 159, 0) 100%),
radial-gradient(27.71% 43.1% at -4.01% 104.91%, #00DAF2 0%, rgba(83, 175, 255, 0) 100%),
linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
radial-gradient(51.22% 85.97% at 50% 50%, rgba(26, 255, 159, 0.8) 0%, rgba(1, 87, 69, 0) 98.96%),
linear-gradient(0deg, #005745, #005745), #FFFFFF;
}

#newsuperjob:hover #s_salute{
  transform: scale(2);
}

#newsuperjob:hover #s_heart{
  transform: scale(1.1);
}

#newsuperjob:hover #s_present{
  transform: translateX(10%) translateY(10%) scale(1.1);
}

#newsuperjob:hover #s_portfl{
  transform: translateX(-10%) translateY(10%) scale(1.1);
}

#newsuperjob:hover #s_iphone{
  transform: translateX(-10%) translateY(-10%) scale(.9);
}

#newsuperjob:hover #s_laptop{
  transform: translateX(10%) translateY(-10%) scale(.9);
}

#newsuperjob:hover #s_funnel{
  transform: translateX(20%) translateY(10%) scale(.8);
}

#newsuperjob:hover #s_calc{
  transform: translateX(-20%) translateY(-10%) scale(.8);
}

#newsuperjob:hover #s_pichart{
  transform: translateX(-30%) translateY(20%) scale(.8);
}



#newsuperjob h3{
  position: absolute;
  text-align: center;
  left: 17%;
  top: 21%;
  font-size: 250%;
  font-weight: 900;
  background: #00000070;
  padding-left: 3%;
  padding-right: 3%;
  border-radius: 10rem;
  transition: 1s;
}
#newsuperjob:hover h3{
  transform: scale(.5);
  opacity: 0;
}
#vsbere{
  grid-gap: 1%;
background: radial-gradient(37.04% 82.13% at 81% 51.98%,rgba(63,183,190,.5) 0,rgba(63,183,190,.358) 18.75%,rgba(63,183,190,.216) 42.71%,rgba(63,183,190,.094) 69.27%,rgba(63,183,190,0) 100%),linear-gradient(213.26deg,#021824 13.35%,#083047 86.34%);
display: grid;
grid-template-areas: ". . A A A A A A . . . . . .";
grid-template-columns: repeat(14,1fr);
padding: 3rem 0;
position: relative;
  background: radial-gradient(31.39% 56.28% at 4.63% 95.5%, #FEF600 0%, rgba(254, 246, 0, 0) 100%),
  radial-gradient(70.12% 77.63% at 95.5% 0%, #0087CD 22.88%, rgba(4, 156, 238, 0) 100%),
  radial-gradient(62.8% 109.77% at 98.64% 97.3%, #15A214 0%, rgba(21, 162, 20, 0) 100%),
  radial-gradient(49.87% 78.35% at 4.33% 0%, #3DE9AF 0%, rgba(63, 233, 175, 0) 100%),
  radial-gradient(55.85% 143.71% at 82.85% 54.88%, #43E9AA 2.91%, rgba(67, 233, 170, 0) 100%),
  linear-gradient(180deg, #4DC337 0%, #79D922 100%), #16A121;
}

#vsbere img{
  bottom: 0;
    height: 100%;
    left: 50%;
    position: absolute;
}

#vsbere h3{
  color: black;
}
#vsbere p{
  color: black;
}

#vsbere_txt{
  grid-area: A;
}

#twoyearsSber{
  display: flex;
position: relative;
overflow: hidden;
height: 0;
text-align: center;
padding: 12%;
background: radial-gradient(31.39% 56.28% at 4.63% 95.5%, #FEF600 0%, rgba(254, 246, 0, 0) 100%),
radial-gradient(70.12% 77.63% at 95.5% 0%, #0087CD 22.88%, rgba(4, 156, 238, 0) 100%),
radial-gradient(62.8% 109.77% at 98.64% 97.3%, #15A214 0%, rgba(21, 162, 20, 0) 100%),
radial-gradient(49.87% 78.35% at 4.33% 0%, #3DE9AF 0%, rgba(63, 233, 175, 0) 100%),
radial-gradient(55.85% 143.71% at 82.85% 54.88%, #43E9AA 2.91%, rgba(67, 233, 170, 0) 100%),
linear-gradient(180deg, #4DC337 0%, #79D922 100%), #16A121;
}
#twoyearsSber h3{
  position: absolute;
text-align: center;
left: 50%;
top: 24%;
font-size: 250%;
font-weight: 900;
background: #00000070;
padding-left: 3%;
padding-right: 3%;
border-radius: 10rem;
transition: 1s;
margin-left: -30%;
width: 60%;
line-height: 1.3;
}

.work{
  width: 100%;

  position: relative;
  display: flex;

  display: grid;
  border-radius: 0rem;
  /* background: black; */
  border-top: .3rem solid;
}

.workwrap{
  width: 100%;
  height: 120%;
  position: relative;
    display: grid;
  min-height: 15rem;

}

.work h2{
  position: absolute;
  font-size: 1.7vw;
  top:25%;
  line-height: 1.1;
  width: 100%;
  max-width: 20vw;
  margin: 0;
  margin-left: 1rem;
  margin-top: .5rem;
  transition: .4s;
}

.work #wtime{
  position: absolute;
  top:-20%;
  margin: 0;
  margin-left: 1rem;
  margin-bottom: .5rem;
    transition: .4s;
    font-size: 1rem;
}

.work #job{
  position: absolute;
font-size: 1rem;
margin-left:1rem;
line-height: 1.4;
font-weight: 400;
top: 0%;
transition: .6s;
}
.work #job span{
  font-size:1.1rem;
  font-weight:800;
}

.work #wdescription{
  margin: 1rem;
  margin-left: 0;
line-height: 1.4;
font-size: 1rem;
transition: .7s;
opacity: 0;
}

#wlink{
  text-decoration: none;
}

#sj:hover{
  background: transparent;
}

#sber:hover{
  background: transparent;
}

#artleb:hover{
  background: transparent;
}

#advnz:hover{
    background: transparent;
}

#shablonivk{
  position: relative;
  border-radius: 1rem;
  width: 100%;
  overflow: hidden;
  height: 0;
  padding-bottom: 140%;
      margin-bottom: 1rem;
}

#shablonivk img{
  position: absolute;
  width: 100%;
  transition: 10s;
}

#shablonivk:hover #lenta{
  transform: translateY(-80%);

}

video{
  border-radius: 1rem;
}

#lendosSh{
  grid-area: D;
  position: relative;

}

#shablendos{
  border-radius: .5rem;
  position: relative;
  overflow: hidden;
  width: 100%;
  background: radial-gradient(71.94% 71.94% at 66.92% 61.19%, #38FFE7 0%, rgba(56, 255, 231, 0.804071) 39.06%, rgba(56, 255, 231, 0.416667) 68.23%, rgba(56, 255, 231, 0) 100%), #03331C;
  height: 0;
  padding-bottom: 67%;
      margin-bottom: 1rem;
}

#browseS{
  position: absolute;
  width: 100%;
}

#shablendos:hover #balloons{
  transform: translateY(-20%) scale(1.1);

}

#shablendos:hover #candys_sh{
  transform: translateY(-20%) translateX(-20%) scale(.7);

}

#shablendos:hover #balloon{
  transform: translateY(5%) translateX(5%) scale(1.7);

}

#shablendos:hover #couple{
  transform: scale(1.07);

}

#shablendos imng{
  position: absolute;
}

#balloons{
  position: absolute;
  width:100%;
  left:0;
  transition: 10s;
}
#candys_sh{
    position: absolute;
  width: 50%;
  left:0;
  transition: 5s;
}

#couple{
    position: absolute;
  width: 100%;
left: 0;
transition: 8s;
}

#balloon{
    position: absolute;
  width: 50%;
  right: 0;
  bottom: 0;
  transition: 7s;
}

#cardshablon{
  grid-area: A;
}

.work:hover h2{
  opacity: 0;
  transform: translateX(-1rem);
}

.work:hover #job{
  opacity: 0;
  transform: translateX(-1rem);
}

.work:hover #wtime{
  opacity: 0;
  transform: translateX(-1rem);
}

.work:hover #wdescription{
transform: translateX(1rem);
opacity: 100%;
}

@media (max-width: 900px) {

  #newsuperjob_articles{

    grid-template-areas: "A" "B";
        grid-template-columns: repeat(1, 1fr);

  }

  #newsuperjob_articles2{

    grid-template-areas: "B" "A" "D" ;
    grid-template-columns: repeat(1, 1fr);
  }

  #newsber_articles{

    grid-template-areas: "A" "B" "D" ;
        grid-template-columns: repeat(1, 1fr);
  }
  #newsber_articles2{

    grid-template-areas: "A" "D" ;
        grid-template-columns: repeat(1, 1fr);

  }
#portaldiv{
  display: none;
}


  .work h2{
    position: absolute;
    font-size: 4vw;
    top:0;
    line-height: 1.1;
    width: 100%;
    margin: 0;
    margin-left: 1rem;
    margin-top: 1.7rem;
    transition: .4s;
  }

  #kusBckg{
    display: grid;
    grid-template-columns: 1fr;
      column-gap: 1%;
      padding: 1.5rem;
  }


  #wrapperWork{
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    column-gap: .1rem;
  }
  #wrapperWork2{
    margin-top: 0;
  }
#fr{
  display: none;
}

  #wrapperWork2{
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    column-gap: .1rem;
  }

  #wdescription{
    display: none;
  }

  #ducks{
    position: relative;
    width: 500%;
  }



h1 a{
  text-decoration: none;
}
  h2{
    font-size: 2rem;
  }

  .App {
  display: grid;
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: 'mulish', sans-serif;
  font-size: 1rem;
    line-height: 1.6;
  color: white;
  }

.Plate{
  color: var(--color-main);
  background-color: #00000000;
  font-size: 1.6rem;
  margin-bottom: 2rem;
      line-height: 109%;

}

.description_1{
  max-width: 80%;
    margin-left: 10%;
      display: flex;
      flex-direction: column;
    align-content: center;
    align-items: center;
    flex-wrap: nowrap;
}

.About{

}

.MuiPaper-root{
  display: none;
}

#delau1{

}

.wraper{
  display: flex;
  margin-left: 10%;
}

#deco{
  position: absolute;
  z-index: 1;
  width: 50%;
}
#catdec{
  position: absolute;
  z-index: 1;
  right: 0;
  width: 30%;
}


.prostina{
  display: grid;
      grid-template-areas:
  "A A A A A A A A A A A A"
  "B B B B B B B B B B B B"
  "C C C C C C C C C C C C"
  "D D D D D D D D D D D D"
  "E E E E E E E E E E E E"
  "F F F F F F F F F F F F"
  "G G G G G G G G G G G G";
      width: fit-content;
      /* flex-direction: row; */
      /* justify-content: space-evenly; */
      align-items: flex-start;
}

.doBlock{
  display: grid;
      width: fit-content;
      grid-template-columns: 100%;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: flex-start;
      transition: .6s;

}



#kusWrap{
  margin: 0rem;
  border-radius: 0rem;
  padding: 0rem;
  padding-top: 0;
  width: 100%;
  background: #00000000;
}

.doCard{
  width:100%;
  transition: .6s;
}

.scrollableContainer {
  margin-top: 5rem;
  position: relative;
  -ms-overflow-style: none;
   scrollbar-width: none;
   overflow-y: scroll;
       margin-left: 10%;
       display: block;
}
.list1 {
  display: none;
}
#divList{
  display: none;
}

.App {
  font-size: 3vw;
}

#newsuperjob h3{
  position: absolute;
  text-align: center;
  left: 21%;
  top: 25%;
  font-size: 100%;
  font-weight: 900;
  background: #00000070;
  padding-left: 3%;
  padding-right: 3%;
  border-radius: 3rem;
  transition: 1s;
}

}
